.container {
    width: 100%;
    margin: 0 auto;
    max-width: 600px;
}

.logo-box {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 40px 0;
    margin-left: auto;
}

.body {
    background-color: #002b50;
    color: aliceblue;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.user-photo > img {
    border-radius: 30px;
    border: solid 8px aliceblue;
    width: 225px;
    height: 225px;
    object-fit: cover;
}

.user-info-box > h2 {
    font-size: 1.5em;
}

.user-photo {
    display: flex;
    width: 85%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.user-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.contact-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.contact-icons {
    background-color: white;
    color: #06284c;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin: 11px;
}

.contact-box-vertical > a {
    display: flex;
    align-items: center;
    gap: 15px;
    color: white;
    text-decoration: none;
}

.contact-box-vertical {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: auto;
    align-items: flex-start;
    padding: 20px 0;
    max-width: 67%;
    @media (max-width: 767px) {
        margin: 0 auto;
        align-items: center;
    }
}

.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
}

.country-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 20px;
    @media (max-width: 767px) {
        gap: 19px;
        font-size: 14px;
    }
}

.footer-icons {
    background-color: white;
    color: #06284c;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 11px;
}
.error-message {
    text-align: center;
    padding: 40px;
    font-weight: bolder;
    margin-top: 25%;
}
.organization-level {
    font-size: 16px;
}
